<div class="fila">
  <div class="p-grid filaUser">

    <div class="p-col-3 boxImg">
      <img src="./assets/imgUsuarios/user.png"> <!-- reemplazar por la foto de perfil real -->
    </div>

    <div class="p-col">
      <p class="txtNomUsuario">{{usuarioDTO.sNombres}}</p>
      <p class="txtEstadoUsuario">{{usuarioDTO.cIdentificacion}}</p>
    </div>

    <div class="p-col-2 d-flex flex-row flex-nowrap justify-content-around align-items-stretch">
      <div class="d-flex flex-column justify-content-center mx-1">
        <a class="text-dark grow-on-hover fas fa-file-invoice-dollar py-0 my-0"
          placement="left" ngbTooltip="Super Admin: Ver info de {{usuarioDTO.sNombres}}"
          (click)="onSuperAdminClickHandler()"></a>
      </div>
      <div class="d-flex flex-column justify-content-center mx-1">
        <p class="fas fa-chevron-right py-0 my-0"></p> 
      </div>
    </div>

  </div>
  <div class="separador"></div>
</div>