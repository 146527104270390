import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { JwtService } from 'src/app/service/implementacionServicios/jwt-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificacionServiceImp } from 'src/app/service/implementacionServicios/notificacion-service-imp';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LoginServiceImp } from 'src/app/service/implementacionServicios/login-service-imp';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Persona } from 'src/app/modelo/clasesGenericas/ClasesResponse';
import { ComunicacionSvc } from 'src/app/service/comunicacion/Comunicacion.service';
import { General } from 'src/app/modelo/General';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent extends General implements OnInit {
    public nombres: string = 'N/A';
    public fechaConexion: string = 'N/A';
    public codigo_usuario: number;
    public genero: string;
    public fotoPerfil: string;
    public notificacionesSinLeer: number = 0;
    public notificacionesParaLeer: Array<string> = [];
    public loading: boolean = false;
    message = new FormControl('Info about the action');
    positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
    position = new FormControl(this.positionOptions[0]);
    public persona: Persona = {
        CIdentificacion: '',
        ITipo: 0,
    };

    items: MenuItem[];

    @Input() tipoNavbar: string = 'normal';

    constructor(
        public rutaE: Router
        /* private jwtService: JwtService,
         private modalService: NgbModal,
         private notificacionService: NotificacionServiceImp,
         private primengConfig: PrimeNGConfig,
         private ruta: Router,
         private loginService: LoginServiceImp,
         private confirmationService: ConfirmationService,
         private messageService: MessageService,
         private comunicacion: ComunicacionSvc*/
    ) {
        super(rutaE, null);
    }
    ngOnInit(): void {
        this.setData();
    }

    /*
    ngOnInit(): void {
        if (this.tipoNavbar == 'session') {
            this.primengConfig.ripple = true;
            this.cargarNavbarSession();
        }
        this.items = [
            {
                label: this.nombres,
                command: () =>
                    localStorage.getItem('usuario') == 'admin'
                        ? ''
                        : this.informacionUsuario(),
            },
            {
                label: this.fechaConexion !== 'N/A' ? this.fechaConexion : '',
            },


            //{
             // icon: 'fas fa-comments fa-lg',
           //   command: () => this.ruta.navigateByUrl('/admin/solicitudes-pqrsf'),
           //   visible: localStorage.getItem('usuario') == 'admin' ? true : false,
          //  }
        ];
    }*/


    // usado multiples veces
    /* cargarNavbarSession() {
         this.getNotificacionesSinLeer();
         this.getNombres();
         this.getFotoPerfil();
         this.getGenero();
         this.getFechaConexion();
         this.getCodigoUsuario();
         //this.getNotificaciones();
 
         // me suscribo al evento de cambiar foto de perfil
         this.comunicacion.eventEmitter.subscribe(
             evt => {
                 if (evt.evento && evt.evento == 'he actualizado foto perfil') {
                     this.getFotoPerfil();
                 }
             }
         );
     }
 
     irHome() {
         localStorage.getItem('usuario') == 'admin' ? this.irHomeAdmin() : this.irEstadoCuenta();
     }
 
     getNotificacionesSinLeer() {
         this.jwtService.getNotificacionSinleer();
         this.notificacionesSinLeer = parseInt(
             sessionStorage.getItem('NotificacionesSinLeer')
         );
     }
 
     getNombres() {
         this.nombres = this.jwtService.getNombres();
         return this.nombres;
     }
 
     getFechaConexion() {
         this.fechaConexion = this.jwtService.getFechaUltimaVez();
         return this.fechaConexion;
     }
 
     getCodigoUsuario() {
         this.codigo_usuario = this.jwtService.getCodUser();
         return this.codigo_usuario;
     }
 
     getFotoPerfil() {
         this.fotoPerfil = atob(localStorage.getItem('fotoPerfil'));
         return this.fotoPerfil;
     }
 
     getGenero() {
         this.genero = this.jwtService.getGenero();
         return this.genero;
     }
 
     getNotificaciones() {
         this.loading = true;
         let tipoIdent = atob(sessionStorage.getItem('tipoId'));
         let ident = atob(sessionStorage.getItem('doi'));
 
         this.persona.ITipo = Number(tipoIdent);
         this.persona.CIdentificacion = ident;
 
         this.notificacionService
             .getNotificacion(this.persona)
             .subscribe(
                 (response: any) => {
                     this.loading = false;
                     if (response != null) {
                         let listaNot = response.listNotificacionUsuario;
                         let contador = 0;
                         if (listaNot != null && listaNot != undefined) {
                             for (let index = 0; index < listaNot.length; index++) {
                                 if (contador <= 2 && listaNot[index].cEstado == 'ACT') {
                                     this.notificacionesParaLeer.push(listaNot[index].cTitulo);
                                     contador += 1;
                                 }
                             }
                         }
                     }
                 });
     }
 
     informacionUsuario() {
 
         if (localStorage.getItem('usuario') == 'asoc') {
             localStorage.setItem('home', 'false');
             this.ruta.navigate(['asociado/informacionUsuario']);
         }
     }
 
     irEstadoCuenta() {
         this.ruta.navigateByUrl('/asociado/home/estado-cuenta');
     }
 
     irHomeAdmin() {
         this.ruta.navigateByUrl('/admin/home')
     }
 
     irNotificaciones() {
         if (localStorage.getItem('usuario') == 'asoc')
             this.ruta.navigateByUrl('/asociado/notificaciones');
     }
 
     cerrarSesion() {
         this.confirmationService.confirm({
             message: '¿Deseas cerrar la sesión?',
             header: 'Vas a cerrar sesión',
             icon: 'pi pi-sign-out',
             acceptIcon: 'pi pi-check',
             rejectIcon: 'pi pi-times',
             acceptLabel: 'Sí, cerrar sesión',
             rejectLabel: 'Cancelar',
             accept: () => {
                 this.loading = true;
                 this.loginService.logout(this.jwtService.getCodUser()).then(
                     (respuesta: any) => {
                         this.loading = false;
                         if (respuesta.bRta == true) {
                             this.loading = true;
                             this.jwtService.clearStorage();
                             localStorage.setItem('cerrar', 'true');
                             this.ruta.navigateByUrl('/');
                         } else {
                             this.messageService.clear();
                             this.messageService.add({
                                 severity: 'error',
                                 summary: respuesta.sMsj,
                                 sticky: true,
                             });
                             //Mostrar pop up error
                             //this.tipoModal = "error";
                             //this.mensaje = respuesta.sMsj;
                             //this.ruta.navigateByUrl('/homePage/estado-cuenta');
                         }
                     },
                     (error) => {
                         this.messageService.clear();
                         this.messageService.add({
                             severity: 'error',
                             summary: 'Ha ocurrido un error inesperado',
                             sticky: true,
                         });
                         //this.tipoModal = "error";
                         //this.mensaje = "Ha ocurrido un error inesperado";
                     }
                 );
             },
         });
 
         //let iPerfil = this.jwtService.getTipoPerfil();       
     }*/


    cerrarSesion() {
        sessionStorage.clear();
        this.redirigirRuta('sencilla', '/admin/login', null);
    }

    setData() {
        // const dataToken = this.datosLogin('jwt');
        let tokenSesion = localStorage.getItem('token');
        ////console.log(dataToken);
        if (tokenSesion === "") {
            this.redirigirRuta('sencilla', 'admin/login', null);
        } else {
            let valorToken = JSON.parse(tokenSesion);
            //console.log("respueta taken", valorToken);
            /* if (!valorToken.valToken.bRta) {
                 this.redirigirRuta('sencilla', 'administ/login', null);
             }*/
        }
    }

}
