/**
 * COMPONENTE MULTISELECT_ELIMINADOR
 * 
 *      toma n inputs que le dan en su ng-container, les agrega selects que escuchan
 *      a un array de datos que son extraídos de un conjunto de datos, array u object
 *      y se encargan de se queden seleccionados todos los select con opciones diferentes.
 * 
 *      @author Andrés Sierra
*/

import { Component, ElementRef, Input, Output, Renderer2, ViewChild, EventEmitter } from '@angular/core';
import { ObserverResponseGenerico } from '../clases';
import { ObserverService } from '../../service/implementacionServicios/observer.service';


@Component({
    selector: 'encuesta',
    templateUrl: './encuesta.component.html',
    styleUrls: ['./encuesta.component.css']
})
export class EncuestaComponent {

    @Input() public id                  : string;
    @Input() public titulo              : string;
    @Input() public classes             : string;
    @Input() public names               : string;
    @Input() public selectClasses       : string;
    @Input() public data                : any[];
    @Input() public extract             : string;
    @Input() public depth               : string;
    @Input() public placeholder         : string;
    @ViewChild('container') container   : ElementRef;
    @Output() onSelection               : EventEmitter<any>;
    @Output() onClean                   : EventEmitter<any>;

    private inputs                  : HTMLInputElement[];
    private selects                 : SelectorListener[];
    private datosExtraidos          : string[];
    private valoresNoSeleccionados  : string[];
    private valoresSeleccionados    : string[];
    public estado                  : string;

    constructor(private renderer: Renderer2, public genericObserver: ObserverService) {
        this.id                     = '';
        this.titulo                 = '';
        this.classes                = '';
        this.names                  = '';
        this.selectClasses          = '';
        this.data                   = [];
        this.extract                = '';
        this.depth                  = '';
        this.placeholder            = '';
        this.onSelection            = new EventEmitter();
        this.onClean                = new EventEmitter();
        this.container              = new ElementRef('span');

        this.inputs                 = [];
        this.selects                = [];
        this.datosExtraidos         = [];
        this.valoresNoSeleccionados = [];
        this.valoresSeleccionados   = [];
        this.estado                 = "sin resolver";
    }




    
    ngAfterViewInit() {
        this.datosExtraidos = this.encontrarValor(this.data, this.extract, parseInt(this.depth), [], true); // recursivo
        this.prepararPreguntas();
    }



    public prepararPreguntas() {
        this.inputs = this.encontrarInputs();
        this.selects = this.encontrarSelects();

        // agrego los selects
        if (this.inputs.length == this.selects.length)
            for (let i = 0; i < this.inputs.length; i++)
                this.renderer.insertBefore(this.inputs[i].parentElement, this.selects[i].select, this.inputs[i]);
        else throw Error('El # de inputs y selects no coincide');

        // me suscribo al observer
        this.genericObserver.emisor$.subscribe(
            (info: ObserverResponseGenerico) => {
                switch (info.codigo) {
                    case 'limpiar': {
                        this.cleanEvent();
                    } break;
                }
            }
        );
    }


    // obtengo los inputs que hay en el ng-container
    private encontrarInputs(): HTMLInputElement[] {
        let resultado: HTMLInputElement[] = [];
        
        let inputContainer: HTMLElement = this.container.nativeElement.childNodes[1];

        for (let i = 0; i < inputContainer.childElementCount; i++)
            if(inputContainer.childNodes[i].nodeName == 'INPUT')
                resultado.push(<HTMLInputElement>inputContainer.childNodes[i]);

        return resultado;
    }




    // creo los selects para cada input
    private encontrarSelects(): SelectorListener[] {
        let resultado: SelectorListener[] = [];

        for (let i = 0; i < this.datosExtraidos.length; i++)
            this.valoresNoSeleccionados.push(this.datosExtraidos[i] + '_' + i);

        let sNames:string[] = [];
        if(this.names && this.names.length > 0) {
            sNames = this.names.trim().split(',');

            if (sNames.length !== this.inputs.length)
                console.log('alerta: componente encuesta dice que la cantidad de [names] no es la misma que la cantidad de inputs');
        }

        if (this.inputs) {
            for (let i = 0; i < this.inputs.length; i++) {
                let s = new SelectorListener(i.toString(), this.renderer, (e, s) => {
                    this.selectionEvent(e, s);
                },
                    'Seleccione una pregunta');
                s.setModelo(this.valoresNoSeleccionados);
                s.select.required = true;
                s.select.name = sNames[i];
                let sClasses:string[] = this.selectClasses.split(' ');
                for(let c of sClasses)
                    this.renderer.addClass(s.select, c);
                s.actualizar();
                resultado.push(s);
            }
        }

        return resultado;
    }




    // extraigo los valores del conjunto de datos que me dan en [data]
    private encontrarValor(data: any, extract: string, depth: number, currentResult: string[], firstRun: boolean): string[] {

        let result: string[] = currentResult;

        // obtengo la longitud de un objeto o de un array
        let length = data.length == undefined ? Object.keys(data).length : data.length;

        // valida que info sea un objeto
        if (data.constructor === {}.constructor && length > 0 && depth > 0) {
            for (let i = 0; i < length; i++)
                this.encontrarValor(Object.entries(data)[i][1],
                    Object.entries(data)[i][0],
                    depth - 1,
                    result,
                    false);
            return result;
        }
        // valida que info sea un array
        else if (data.constructor === [].constructor && length > 0 && depth > 0) {
            for (let i = 0; i < length; i++)
                this.encontrarValor(data[i], i.toString(), depth - 1, result, false);
            return result;
        }
        // crear un extract si el dato es el correcto
        else if (typeof (data) === 'string' || typeof (data) === 'number') {
            if (extract == this.extract || firstRun) {
                data.toString().split(',').forEach(x => result.push(x));
            }
        }

        return result;
    }




    // cuando selecciono un elemento, no permito que los demás selects puedan
    // seleccionar el mismo elemento.
    private selectionEvent(e: Event, s: SelectorListener) {
        if (e.target) {
            if (!this.valoresSeleccionados.find(x => x == s.newVal)) {
                // pasar de noSel a sel
                this.valoresSeleccionados.push(s.newVal!);
                let indicePorEliminar: number = this.valoresNoSeleccionados.indexOf(s.newVal!);
                if (indicePorEliminar >= 0) this.valoresNoSeleccionados.splice(indicePorEliminar, 1);

                // actualizar todos menos el que hizo la selección
                if (this.valoresSeleccionados.find(x => x == s.oldVal)) {
                    this.valoresNoSeleccionados.push(s.oldVal!);
                    let indicePorEliminar: number = this.valoresSeleccionados.indexOf(s.oldVal!);
                    if (indicePorEliminar >= 0) this.valoresSeleccionados.splice(indicePorEliminar, 1);
                }
            }
            else {
                // pasar de noSel a sel
                this.valoresSeleccionados.push(s.newVal!);
                let indicePorEliminar: number = this.valoresNoSeleccionados.indexOf(s.newVal!);
                if (indicePorEliminar >= 0) this.valoresSeleccionados.splice(indicePorEliminar, 1);
            }

            // actualizar todos menos el que hizo la selección
            for (let sel of this.selects)
                if (!sel.equals(s)) {
                    sel.setModelo(this.valoresNoSeleccionados);
                    sel.actualizar();
                }
        }
        this.onSelection.emit(e);
    }




    // cuando selecciono un elemento, no permito que los demás selects puedan
    // seleccionar el mismo elemento.
    private cleanEvent() {

        let form: HTMLFormElement = this.container.nativeElement.childNodes[1];
        let cuantosInputsHabia = this.inputs.length;

        for (let s of this.selects) s.reset();
        this.selects = [];
        this.inputs = [];
        this.valoresNoSeleccionados = [];
        this.valoresSeleccionados = [];
        this.datosExtraidos = [];

        let eliminar: ChildNode[] = [];
        for (let i = 0; i < form.childNodes.length; i++) {
            if (form.childNodes[i].nodeName == 'SELECT')
                eliminar.push(form.childNodes[i]);
        }
        for (let el of eliminar) this.renderer.removeChild(form, el);

        let inputsInForm = 0;
        for(let i=0; i<form.childElementCount; i++) {
            if(form.childNodes[i].nodeName == 'INPUT') inputsInForm++;
        }

        // auto función de espera, porque Renderer2 :: removeChild no es inmediato
        (function espera (desde, hasta, instancia) {
            desde++;
            if( desde <= hasta && 
                inputsInForm != cuantosInputsHabia)
                setTimeout(espera, 1000, desde, hasta, instancia);
            else {
                if(desde <= hasta) {
                    form.reset();
                    instancia.ngAfterViewInit();
                }
            }
        })(0, 60, this);


        this.onClean.emit();
    }

    public getSelectsValues() : string[]{
        let selectsValues:string[] = [];

        for(let s of this.selects) 
            selectsValues.push(s.newVal.split('_')[0]);

        return selectsValues;
    }
}








// Clase que puede ser genérica, un select que toma un modelo de datos y 
// puede actualizarse
class SelectorListener {
    public id: string;
    public renderer: Renderer2;
    public modelo: string[];
    public select: HTMLSelectElement;
    public options: HTMLOptionElement[];
    public oldVal: string | undefined;
    public newVal: string | undefined;
    public haSeleccionado: boolean;
    public placeholder: string;

    constructor(id: string, renderer: Renderer2, onSelectionCb: (e: Event, s: SelectorListener) => any, placeholder: string) {
        this.id = id;
        this.renderer = renderer;
        this.modelo = [];
        this.select = this.renderer.createElement('select');
        this.options = [];
        this.haSeleccionado = false;
        this.placeholder = placeholder;

        this.select.id = id;
        let thisHolder = this;
        this.select.addEventListener('change', e => {
            this.haSeleccionado = true;
            this.newVal = e.target ? (<HTMLSelectElement>e.target).value : 'undefined';
            // on select del llamador
            onSelectionCb(e, thisHolder);
            this.oldVal = this.newVal;
        });
    }

    // obtengo un modelo de lookupvalues
    public setModelo(modelo: string[]) {
        this.modelo = modelo;
    }

    // actualizo mi modelo de datos
    public actualizar() {
        let selectBackup: string | undefined = undefined;

        if (this.haSeleccionado)
            selectBackup = this.newVal;

        // limpio el select
        this.select.innerHTML = '';
        this.options = [];
        let opcPlaceholder: HTMLOptionElement = this.renderer.createElement('option');
        opcPlaceholder.value = '';
        opcPlaceholder.textContent = this.placeholder;
        if (!this.haSeleccionado) opcPlaceholder.selected = true;
        opcPlaceholder.disabled = true;
        this.renderer.appendChild(this.select, opcPlaceholder);

        // actualizo los valores del select
        for (let i = 0; i < this.modelo.length; i++) {
            let opcion: HTMLOptionElement = this.renderer.createElement('option');
            opcion.value = this.modelo[i];
            opcion.textContent = this.modelo[i].split('_')[0];
            this.renderer.appendChild(this.select, opcion);
        }

        // rescata el valor si se ha seleccionado y se está actualizando
        if (this.haSeleccionado) {
            let opcion: HTMLOptionElement = this.renderer.createElement('option');
            opcion.value = selectBackup!;
            opcion.textContent = selectBackup!.split('_')[0];
            opcion.selected = true;
            this.renderer.appendChild(this.select, opcion);
        }
    }

    public reset() {
        this.modelo = [];
        this.options = [];
        this.haSeleccionado = false;
    }

    public equals(elemento: any) {
        return (
            elemento.constructor.name === 'SelectorListener' &&
            (<SelectorListener>elemento).modelo === this.modelo &&
            (<SelectorListener>elemento).id === this.id
        );
    }
}