<div class={{classes}} *ngIf="data.length > 0 else empty">
    
    <span #container>
        <div><h4 *ngIf="titulo">{{titulo}}</h4></div>
        <ng-content select="[formulariod]"></ng-content>
    </span>


</div>
    
<!--if empty-->
<ng-template #empty>
    <p>Lo sentimos, no se obtuvieron las preguntas de seguridad</p>
</ng-template>