<!--<app-spinner *ngIf="loading"></app-spinner>
<p-toast position="top-right"></p-toast>
<p-confirmDialog #cd>
    <button type="button" pButton (click)="cd.accept()"></button>
    <button type="button" pButton (click)="cd.reject()"></button>
</p-confirmDialog>


<p-menubar [model]="items">
    <ng-template class="d-flex flex-row justify-content-between align-items-center" pTemplate="start">
        <a class="text-secondary mt-3 mx-4 home" (click)="irHome()">
            <span class="fas fa-home" ></span>
            &nbsp;Inicio
        </a>
       
        <ng-container *ngIf="false else avatarPorGenero">
          <img [src]="fotoPerfil" height="30" class="p-ml-2 img-circle" (click)="this.informacionUsuario()"/>
      </ng-container>

        <ng-template #avatarPorGenero>
            <img *ngIf="genero == 'M'" src="./assets/imgUsuarios/avatar_masculino.png" height="30" class="p-ml-2 "
                (click)="this.informacionUsuario()" />
            <img *ngIf="genero == 'F'" src="./assets/imgUsuarios/avatar_femenino.png" height="30" class="p-ml-2 "
                (click)="this.informacionUsuario()" />
        </ng-template>

    </ng-template>

    <ng-template pTemplate="end">
        <span class="p-overlay-badge p-mr-6">
            <a (click)="irNotificaciones()" class="pi pi-bell text-secondary"
                style="font-size: 1.5em; cursor: pointer;"></a>
            <span class="p-badge"> {{ notificacionesSinLeer }} </span>
        </span>       
        <i class="pi pi-sign-out" style="font-size: 1.5rem; cursor: pointer;" (click)="cerrarSesion()"></i>
    </ng-template>
</p-menubar>-->


<!-- <div id="menuPc">
    <div class="p-grid inicioMenu">
        <div class="p-col-1">
            <img src="./assets/imgLongAfiliacion/logo_coopedac_1.png" class="logoBar" />
        </div>
        <div class="p-col-11">
            <div class="p-grid backMenu">
                <div class="p-col-2 colTxt" (click)="redirigirRuta('sencilla', '/administrador/home', null)">
                    <p class="txtHome">
                        <i class="pi pi-home iconHome"></i>
                        Inicio
                    </p>
                </div>
                <div class="p-col-2 colTxt">
                    <p class="txtBar">Solicitudes</p>
                </div>

                <div class="p-col-6 colTxt">
                </div>
                <div class="p-col-2 colTxt">
            <p class="txtBar">Contraseñas</p>
          </div>
          <div class="p-col-2 colTxt">
            <p class="txtBar">Administración</p>
          </div>
          <div class="p-col-2 colTxt">
            <p class="txtBar">Seguridad</p>
          </div>
                <div class="p-col-2">
                    <div class="p-grid">
                        <div class="p-col-10 colTxtInfo">
                            <p class="txtBar">Administrador</p>
                            <p class="txtInfo cerrar" (click)="cerrarSesion()">Cerrar sesión</p>
                        </div>
                        <div class="p-col-2">
                           
                            <p-avatar icon="pi pi-user" styleClass="p-mr-2" size="xlarge" shape="circle"></p-avatar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div id="menuPc mb-4">
    <div class="p-grid inicioMenu">
        <div class="p-col-2 d-flex align-items-center">
            <img src="./assets/app/spinnerCoop.png" class="logoBar" />
        </div>
        <div class="p-col-10">
            <div class="p-grid backMenu">
                <div class="p-col-3 colTxt" (click)="redirigirRuta('sencilla', '/admin/home-administrador', null)">
                    <p class="txtBar">Inicio
                        <!-- <i class="pi pi-home iconHome"></i> -->
                    </p>
                </div>

                <div class="p-col-3 colTxt" (click)="redirigirRuta('sencilla', '/admin/solicitudes-afiliacion', null)">
                    <p class="txtBar">Solicitudes</p>
                </div>

                <div class="p-col-3 colTxt">
                    <p class="txtBar">Administrador</p>
                </div>

                <div class="p-col-3 colTxt">
                    <p class="txtBar cerrar" (click)="cerrarSesion()">Cerrar sesión</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="menuMovil">
    <p-button type="button" icon="pi pi-bars"></p-button>

    <!--<p-sidebar [(visible)]="menuMovil">
      <img src="./assets/Img/logo.png" class="logoBar" />
      <div class="colTxt" (click)="redirigirRuta('sencilla', '/administrador/home', null)">
        <p class="txtBar">Inicio</p>
      </div>
      <div class="colTxt">
        <p class="txtBar">Solicitudes</p>
      </div>      
    </p-sidebar>-->
</div>