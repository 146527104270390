// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // URL_SERVICE: 'http://localhost:8080/WSRestApiCoopedacAfi/APIRest/', // Desarrollo local

  URL_SERVICE: 'https://tsafiliacioncoopedacbe.ebsproductodigital.com/WSRestApiCoopedacAfi/APIRest/',// pruebas

    //Producción:

    //  URL_SERVICE: 'https://coopedacafiliacionbe.ebscreditodigital.com/WSRestApiCoopedacAfi/APIRest/',    //produccion
    // URL_SERVICE: 'https://afiliaciondigitalbe.coopedac.com/WSRestApiCoopedacAfi/APIRest/', //produccion
    URL_FTP: 'http://190.85.106.122:8082/FTPCooperagro/'

};
/*  ng build --output-hashing=all --base-href /CoopedacAfiliacion/
 * ng build --output-hashing=all --prod --base-href /CoopedacAfiliacion/
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
