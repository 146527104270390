import { EventEmitter, Injectable } from '@angular/core';
import { ObserverResponseGenerico } from '../../modelo/clases';

@Injectable({
    providedIn: 'root'
})
export class ObserverService {

    public evtEmitter$:EventEmitter<string>;
    public emisor$:EventEmitter<ObserverResponseGenerico>;

    constructor() {
        this.evtEmitter$ = new EventEmitter<string>();
        this.emisor$ = new EventEmitter<ObserverResponseGenerico>();
    }
}