import { DEFAULT_CURRENCY_CODE, NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared.module';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';

import localEs from '@angular/common/locales/es'
import { registerLocaleData } from '@angular/common';
// import { AuthInterceptor } from './service/seguridadServicios/AuthInterceptor.services';

registerLocaleData(localEs);


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    TooltipModule,
    ButtonModule,
    RippleModule,
    InputTextModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: '',
    },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
