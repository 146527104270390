import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-steps-afiliacion',
  templateUrl: './steps-afiliacion.component.html',
  styleUrls: ['./steps-afiliacion.component.css']
})
export class StepsAfiliacionComponent implements OnInit {

  @Input() stepActivo:string;

  constructor() {
  }

  ngOnInit(): void {
    this.cambiarEstadoSteepes(this.stepActivo);
  }

  async cambiarEstadoSteepes(nomEstado){
    switch (nomEstado) {
      case "INFO_PERSONAL":
        await this.delay(3000);
        $("#stepInfoPersonal").addClass("active");
        break;
      case "INFO_COMERCIAL":
        $("#stepInfoPersonal").addClass("active");
        $("#stepInfoComercial").addClass("active");
        $("#lineaInfoPerDer").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaInfComIzq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        break;
      case "INFO_DOCS":
        await this.delay(1000);
        $("#stepInfoPersonal").addClass("active");
        $("#stepInfoComercial").addClass("active");
        $("#stepInfoDocs").addClass("active");
        $("#lineaInfoPerDer").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaInfComIzq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaInfComDer").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaDocIzq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        break;
      case "INFO_AUTORIZACIONES":
        // await this.delay(1000);
        $("#stepInfoPersonal").addClass("active");
        $("#stepInfoComercial").addClass("active");
        $("#stepInfoDocs").addClass("active");
        $("#stepAutorizacion").addClass("active");
        $("#lineaInfoPerDer").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaInfComIzq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaInfComDer").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaDocIzq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        $("#lineaDocDer").removeClass("md-step-bar-right").addClass("md-step-bar-right-active");
        $("#lineaAutoIzq").removeClass("md-step-bar-left").addClass("md-step-bar-left-active");
        break;
      default:
        $("#stepInfoPersonal").addClass("active");
        break;
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}
