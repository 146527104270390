import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsuarioDTO } from '../clasesGenericas/ClasesResponse';

@Component({
  selector: 'fila-usuario',
  templateUrl: './fila-usuario.component.html',
  styleUrls: ['./fila-usuario.component.css']
})
export class FilaUsuarioComponent implements OnInit {

  @Input()  usuarioDTO             : UsuarioDTO | undefined;
  @Output() onSuperAdminClick      : EventEmitter<UsuarioDTO>;

  constructor() {
    this.usuarioDTO = undefined;
    this.onSuperAdminClick = new EventEmitter<UsuarioDTO>();
  }

  ngOnInit(): void {
  }

  public onSuperAdminClickHandler() {
    this.onSuperAdminClick.emit(this.usuarioDTO);
  }

}
